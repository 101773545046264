import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ServiceOverviewPage = ({ data: { page } }) => (
  <MainLayout
    variant="White"
    header={(
      <Header
        text={<PortableText value={page._rawDescription} />}
        headline={page.title}
        variant="image-overlay"
        theme="light"
        bgImageUrl="url(/images/backgrounds/white.webp)"
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: sanityServiceOverview(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      title
      slug {
        current
      }
      headline
      _rawDescription
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...SolutionCardList
        ...OfficeCardList
        ...ContentCardList
        ...CtaCard
        ...ContentSection
      }
    }
 }
`;

ServiceOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ServiceOverviewPage;
